/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Service Worker
 * @author     Fynn Korsen <fynn.korsen@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initServiceWorker = function() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/serviceWorker.js').then(function(/*reg*/) {
                // success
                // console.log('Registrierung erfolgreich. Scope ist ' + reg.scope);
            }).catch(function(/*error*/) {
                // error
                // console.log('Registrierung fehlgeschlagen mit ' + error);
            });
        }
    };
})(jQuery);