/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Fine
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

var Main = {

    /**
     * init/constructor method
     */
    init : function() {
        this.domReady();
        this.onLoad();
		this.onResizeThrottle();
    },

    /**
     * Binding for domReady-Event
     * Note: we don't need to wrap this with $(function() {}) because
     * at this stage we effectively have parsed the whole DOM already
     */
    domReady : function() {
        $(document).ready(function() {
            /** init service worker **/
            $().initServiceWorker();
            
            if($('.c-main').hasClass('is--nostage')){
                $('.c-header').addClass('is--grey');
            }

            if($('.c-main').hasClass('is--superspatz')){
                $('.c-header').addClass('is--black');
            }

            $('.c-nav__toggle').click(function(){
                $(this).toggleClass('is--active');    
                $('.c-nav__menu').toggleClass('is--active'); 
            });
            
            var page = location.pathname.split('/').pop();
            $('.c-menu__list-item a[href="' + page + '"]').addClass('is--active');
        });
    },

    /**
     * Binding for onLoad-Event
     */
    onLoad : function(){
        $(window).on('load', function() {
            $('html').removeClass('no-transition');
        });
    },

    /**
     * Binding for onResize-Event (Throttle 500ms)
     */
    onResizeThrottle : function() {
        var resized;
        var html = $('html');

        $(window).on('resize', function() {
            clearTimeout(resized);
            html.addClass('no-transition');
            resized = setTimeout(doneResizing, 500);
        });

        function doneResizing() {
            html.removeClass('no-transition');
        }
    }
};

// Init
Main.init();
